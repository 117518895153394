@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'LineSeedSans';
    font-weight: 100;
    src: url(./assets/fonts/LINESeedSansTH_A_Th.ttf) format("truetype");
  }

  @font-face {
    font-family: 'LineSeedSans';
    font-weight: 400;
    src: url(./assets/fonts/LINESeedSansTH_A_Rg.ttf) format("truetype");
  }

  @font-face {
    font-family: 'LineSeedSans';
    font-weight: 700;
    src: url(./assets/fonts/LINESeedSansTH_A_Bd.ttf) format("truetype");
  }

  @font-face {
    font-family: 'LineSeedSans';
    font-weight: 800;
    src: url(./assets/fonts/LINESeedSansTH_A_XBd.ttf) format("truetype");
  }

  html {
    font-family: "LineSeedSans";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast-body {
  align-items: flex-start !important;
}

.toast-custom-error {
  background-color: #FFDDDD !important;
  color: #E70000 !important;
  border: 1px solid #E70000 !important;
  font-weight: 700;
  font-family: 'LineSeedSans' !important;
  min-height: unset !important;
  border-radius: 8px !important;
}

.toast-custom-error-body {
  padding: 0 !important;
} 

.toast-custom-success {
  background-color: #ECFDF3 !important;
  color: #17B26A !important;
  border: 1px solid #17B26A !important;
  font-weight: 700;
  font-family: 'LineSeedSans' !important;
  min-height: unset !important;
  border-radius: 8px !important;
}

.toast-custom-success-body {
  padding: 0 !important;
} 

.toast-custom-info {
  background-color: #EDFAFF !important;
  color: #1594FF !important;
  border: 1px solid #1594FF !important;
  font-weight: 700;
  font-family: 'LineSeedSans' !important;
  min-height: unset !important;
  border-radius: 8px !important;
}

.toast-custom-info-body {
  padding: 0 !important;
} 

.Toastify__close-button {
  display: none;
}

.Toastify__toast-icon {
  margin-inline-end: 8px !important;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    padding: 10px !important;
  }
}
